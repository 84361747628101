import React, {useState} from "react";
import "../components/infographics/styles.css";
import SEO from "../components/seo"

import Grid from "@material-ui/core/Grid";

import { Link } from "gatsby"

import SearchIcon from "@material-ui/icons/Search";
import LanguageIcon from "@material-ui/icons/Language";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import EmailIcon from "@material-ui/icons/Email";

import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";

// App bar test
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";

// Menu
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function Infographics() {
    // function here
  function openMail(){
    var email = "eric@campbelldatascience.com";
    var subject = "CDS Infographics";
    {/* var emailBody = 'Hi Eric'; */}
    document.location = "mailto:"+email+"?subject="+subject;
    handleClose();
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO
  // need a darker colour than steelBlue for the intro [x]
  // on gatsby - fill blue to edge?? [x] css body margin 0px
  // now everything needs 8px padding [x]
  // links [x] complete links []
  // header image from royalty free image site []
  // copy for each section [x]
  // swap marketing and seo icons? [x] done, don't like the balance, swap pos [x]

  // hire menu item now should go to Olivia/designer page [x]
  // articles goes to article section [x]
  // contact should open email or go to about page [x]

  // how do we get here from the homepage? in addition to current link [x]
  // what is happening with the headers for the designer profiles? []
  // link from articles to designer profile [-]

  //return here

  return (
    <div
      style={{
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
    <SEO
      title="Infographics | How to Make Yours"
      description="The best place to find infographic designers online. Learn everything you need to know about the design and outsourcing all in one place"
    />

      {/* APP BAR TEST */}
      <div>
        <AppBar
          elevation={0}
          position="static"
          style={{ backgroundColor: "steelBlue", height: "60px" }}
        >
          <Toolbar style={{ paddingRight: "0px", paddingLeft: "10px" }}>
            <Typography variant="h6">
            <Link to="/" style={{ textDecoration: "none", color: "#d6ebf2" }}>
              CDS
            </Link>
            </Typography>

            {/* Button inserted... */}
            <div style={{ marginLeft: "auto" }}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ paddingRight: "0px" }}
              >
                <IconButton
                  edge="start"
                  aria-label="menu"
                  style={{ color: "#d6ebf2" }}
                >
                  <MenuIcon />
                </IconButton>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
            
              <Link to="/infographics/oliviareaney/" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleClose}>hire now</MenuItem>
              </Link>
                
                <a
                  href="#learn"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={handleClose}>learn more</MenuItem>
                </a>


                <MenuItem onClick={openMail}>contact</MenuItem>

              </Menu>
            </div>
            {/* ....Button ended */}
          </Toolbar>
        </AppBar>
      </div>
      {/* INTRODUCTION */}

      <div
        style={{
          backgroundColor: "#d6ebf2",
          paddingBottom: "0px",
          marginBottom: "30px",
          // spacing from div, transfer to text [-]
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        <Grid container justify="space-evenly" spacing={1}>
          {/* FIRST INTRODUCTION SECTION */}
          <Grid item xs={12} sm={6}>
            <h1
              style={{ color: "#315b7d", fontWeight: "200", paddingTop:"15px" }}
            >
              INFOGRAPHICS
            </h1>
            <p style={{ color: "#315b7d" }}>
              The best place to find infographic designers online
            </p>

            <Link to="/infographics/oliviareaney/" style={{ textDecoration: "none"}}>
            <Button
              variant="contained"
              // color="primary"
              disableElevation
              style={{
                backgroundColor: "steelBlue",
                color: "#d6ebf2"
              }}
            >
              hire a designer
              <SearchIcon style={{ paddingLeft: "8px" }} />
            </Button>
            </Link>


          </Grid>
          {/* SECOND INTRODUCTION SECTION */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              paddingTop: "20px"
            }}
          >
            <img
              src="https://i.imgur.com/cMZzRId.png"
              //src="https://picsum.photos/1000/750"
              alt="cds placeholder tag"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%"
              }}
            />
          </Grid>
        </Grid>
      </div>

      {/* CONTAINER */}
      <Grid
        container
        justify="space-evenly"
        spacing={1}
        style={{ paddingLeft: "8px", paddingRight: "8px" }}
      >
        {/* ITEMS */}
        <Grid
          item
          xs={12}
          sm={4}
          style={{ textAlign: "center", paddingBottom: "30px" }}
        >
          <LanguageIcon style={{ color: "steelblue", fontSize: "40" }} />
          <hr style={{ width: "120px", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }} />
          <h2 style={{ fontSize: "1.6rem", fontWeight: "400" }}>Marketing</h2>
          <p
            style={{ fontSize: "1.2rem", color: "dimGrey", fontWeight: "200" }}
          >
            Reach new potential customers with content that shares itself
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{ textAlign: "center", paddingBottom: "30px" }}
        >
          <SearchIcon style={{ color: "steelblue", fontSize: "40" }} />
          <hr style={{ width: "120px", marginTop: "20px", marginLeft: "auto", marginRight: "auto"  }} />
          <h2 style={{ fontSize: "1.6rem", fontWeight: "400" }}>SEO</h2>
          <p
            style={{ fontSize: "1.2rem", color: "dimGrey", fontWeight: "200" }}
          >
            Get more backlinks and increase your organic search ranking
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{ textAlign: "center", paddingBottom: "30px" }}
        >
          <LibraryBooksIcon style={{ color: "steelblue", fontSize: "40" }} />
          <hr style={{ width: "120px", marginTop: "20px", marginLeft: "auto", marginRight: "auto"  }} />
          <h2 style={{ fontSize: "1.6rem", fontWeight: "400" }}>Educate</h2>
          <p
            style={{ fontSize: "1.2rem", color: "dimGrey", fontWeight: "200" }}
          >
            Use public and business data to help sell your products & services
          </p>
        </Grid>
      </Grid>

      {/* SELLING SECTION */}
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <p style={{ fontSize: "1.4rem", color: "dimGrey" }}>Using
          {" "}<strong style={{ color: "steelBlue", fontWeight: "400" }}>
             Infographics
          </strong>{" "}
          as part of your marketing strategy is one of the best ways to generate leads for your business. Here you can find the best freelance graphic designers who specialise in infographics. They are experienced in working with some of the largest businesses in the world and you can hire them in just a few clicks. Have a look at our designer profiles below where you can learn about who you will be hiring. Find out what you can expect when you work with them and how you should prepare to get the best experience.
          </p>
      </div>

      {/* CONTAINER */}
      <Grid
        container
        justify="space-evenly"
        spacing={1}
        style={{
          paddingBottom: "20px",
          paddingLeft: "8px",
          paddingRight: "8px"
        }}
      >
        {/* DESIGNER CARDS TITLE*/}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ textAlign: "center", paddingBottom: "10px" }}
        >
          <h2
            style={{
              color: "steelBlue",
              fontWeight: "400",
              fontSize: "2.2rem"
            }}
          >
            Designer Highlights
          </h2>
        </Grid>

        {/* DESIGNER 1 */}
        <Grid
          item
          xs={6}
          sm={4}
          style={{ textAlign: "center", paddingBottom: "30px" }}
        >
          <Paper style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <Link to="/infographics/oliviareaney/" style={{ textDecoration: "none", color: "#303030"}}>
                <h3 style={{ paddingTop: "15px", marginBottom:"20px"}}>OLIVIA</h3>
              </Link>
            <Link to="/infographics/oliviareaney/" style={{ textDecoration: "none"}}>
            <img
              //src="https://picsum.photos/1200/750"
              src="https://i.imgur.com/LGHkbfm.jpg"
              alt="OliviaReaney profile picture"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                // height: "60%",
                maxWidth: "100%",
                // This is how I cropped the image
                clipPath: "inset(0px 0px 30px 0px)",
                marginBottom: "-15px",
              }}
            />
            </Link>
            <p
              style={{
                fontSize: "0.9rem",
                fontWeight: "200",
                textAlign: "left",
                padding: "5px",
                paddingTop:"0px"
              }}
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id
              consectetur elit, euismod condimentum tellus. Aliquam quis nulla
              dictum, molestie nunc quis, lacinia est. Nullam id dui vel augue
              ultrices fringilla. */}
              I specialize 
              in infographic and help brands tell their stories. I started my own 
              business two years ago after gaining great experience at two NYC agencies. 
              I have worked with a variety of clients in different areas...
            </p>

            <Link to="/infographics/oliviareaney/" style={{ textDecoration: "none"}}>
              <Button
                variant="contained"
                // color="primary"
                disableElevation
                size="small"
                style={{
                  backgroundColor: "steelBlue",
                  color: "white",
                  marginBottom: "10px"
                }}
              >
                VIEW PROFILE
              </Button>
            </Link>

          </Paper>
        </Grid>
        {/* DESIGNER 2 */}
        {/* <Grid
          item
          xs={6}
          sm={4}
          style={{ textAlign: "center", paddingBottom: "30px" }}
        >
          <Paper style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <h3 style={{ paddingTop: "15px", marginBottom:"20px"}}>JOHN</h3>
            <img
              src="https://picsum.photos/1201/750"
              alt="infographics placeholder tag"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                // height: "60%",
                maxWidth: "100%"
              }}
            />
            <p
              style={{
                fontSize: "0.9rem",
                fontWeight: "200",
                textAlign: "left",
                padding: "5px",
                paddingTop:"0px"
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id
              consectetur elit, euismod condimentum tellus. Aliquam quis nulla
              dictum, molestie nunc quis, lacinia est. Nullam id dui vel augue
              ultrices fringilla.
            </p>

            <Link to="/infographics/oliviareaney/" style={{ textDecoration: "none"}}>
            <Button
              variant="contained"
              // color="primary"
              disableElevation
              size="small"
              style={{
                backgroundColor: "steelBlue",
                color: "white",
                marginBottom: "10px"
              }}
            >
              VIEW PROFILE
            </Button>
            </Link>

          </Paper>
        </Grid> */}

      </Grid>

      {/* SELLING SECTION 2*/}
      <div
        style={{
          backgroundColor: "#d6ebf2",
          paddingBottom: "20px",
          marginBottom: "40px",
          // spacing from div, transfer to text []
          paddingTop: "5px",
          paddingLeft: "8px",
          paddingRight: "8px"
        }}
      >
        <h2 style={{ color: "#315b7d", fontSize: "2rem", fontWeight: "200", paddingTop: "20px" }}>
          LEARN MORE
        </h2>
        <p style={{ fontSize: "1rem", color: "#315b7d" }}>
        If you need to learn more about hiring a freelancer before you get started, we have some great resources for you. Make sure you look at the designer profiles first to see who you want to work with. You can also read our 3 articles below, they quickly take you through infographics as a whole. Starting from how they can help with SEO through to what information you should include  then finally your outsourcing options. You can also get in touch to ask any questions directly and I will get back you.
        </p>
        <Button
          variant="contained"
          // color="primary"
          disableElevation
          style={{
            backgroundColor: "steelBlue",
            color: "#d6ebf2"
          }}
          onClick={openMail}
        >
          get in touch
          <EmailIcon style={{ paddingLeft: "8px" }} />
        </Button>
      </div>

      {/* ARTICLE LINK SECTION */}
      {/* <h2
        style={{
          color: "steelBlue",
          fontWeight: "400",
          fontSize: "2rem",
          paddingLeft: "10px"
        }}
      >
        Further Reading
      </h2> */}

      {/* ARTICLE LINK SECTION */}
      <div style={{ paddingLeft: "8px", paddingRight: "8px" }} id="learn">
        {/* ARTICLE 1 */}
        <div
          style={{
            // backgroundColor: "#FFFAFA",
            border: "1px solid 	#DCDCDC",
            maxWidth: `500px`,
            marginBottom: `1.45rem`,
            padding: "10px",
            borderRadius: "5px"
            // boxShadow: "2px 2px 3px #D3D3D3",
          }}
        >
        <Link to="/infographics-for-seo/" style={{ textDecoration: "none"}}>
          <h3
            style={{
              fontSize: "2rem",
              color: "steelBlue",
              marginTop: "0px",
              marginBottom: "0px",
              fontWeight: "400",
              paddingBottom: "20px"              
            }}
          >
            Infographics for SEO
          </h3>
          </Link>

          <p style={{ fontWeight: "200" }}>
          Search engine optimisation is an important part of your marketing strategy. When done right, it can help you reduce expenses because you are able to pull in more leads without spending on ads.
          </p>
          <Link to="/infographics-for-seo/" style={{ textDecoration: "none"}}>
          <p style={{ color: "steelBlue", marginBottom: "0px" }}>
            continue reading
          </p>
          </Link>
        </div>

        {/* ARTICLE 2 */}
        <div
          style={{
            // backgroundColor: "#FFFAFA",
            border: "1px solid 	#DCDCDC",
            maxWidth: `500px`,
            marginBottom: `1.45rem`,
            padding: "10px",
            borderRadius: "5px"
            // boxShadow: "2px 2px 3px #D3D3D3",
          }}
        >
        <Link to="/infographic-design/" style={{ textDecoration: "none"}}>
          <h3
            style={{
              fontSize: "2rem",
              color: "steelBlue",
              marginTop: "0px",
              marginBottom: "0px",
              fontWeight: "400",
              paddingBottom: "20px"
            }}
          >
            Infographics Design
          </h3>
          </Link>

          <p style={{ fontWeight: "200" }}>
          Infographics should leave your leads informed and more knowledgeable about you and your business. Here's everything you need to know about designing infographics.
          </p>
          <Link to="/infographic-design/" style={{ textDecoration: "none"}}>
          <p style={{ color: "steelBlue", marginBottom: "0px" }}>
            continue reading
          </p>
          </Link>
        </div>

        {/* ARTICLE 3 */}
        <div
          style={{
            // backgroundColor: "#FFFAFA",
            border: "1px solid 	#DCDCDC",
            maxWidth: `500px`,
            marginBottom: `1.45rem`,
            padding: "10px",
            borderRadius: "5px"
            // boxShadow: "2px 2px 3px #D3D3D3",
          }}
        >
        <Link to="/outsourcing-infographics/" style={{ textDecoration: "none"}}>
          <h3
            style={{
              fontSize: "2rem",
              color: "steelBlue",
              marginTop: "0px",
              marginBottom: "0px",
              fontWeight: "400",
              paddingBottom: "20px"
            }}
          >
            Outsourcing Infographics
          </h3>
          </Link>

          <p style={{ fontWeight: "200" }}>
          You’ve decided you want hire a designer to get some infographics made and don’t know where to start? Here are your options and tips on what to do next.
          </p>
          <Link to="/outsourcing-infographics/" style={{ textDecoration: "none"}}>
          <p style={{ color: "steelBlue", marginBottom: "0px" }}>
            continue reading
          </p>
          </Link>
        </div>
      </div>



      <footer
          style={{
            fontFamily: "Arial, Helvetica, sansSerif",
            fontSize: "0.9rem",
            color: "#3d3d3d",
            paddingLeft:"8px",
            paddingRight:"8px"
          }}
        >
        <Grid
        container
        direction="row"
        spacing={2}
        justify="center"
      >

      <Grid item xs={6} style={{textAlign: "left"}}>
  
          <Link
            to="/about/"
            style={{ color: "#3d3d3d", textDecoration: "none" }}
          >
            {/* © {new Date().getFullYear()}, Built by
          {` `}
          <a href="https://www.gatsbyjs.org">International Shopper</a> */}
            © {new Date().getFullYear()} CDS
          </Link>

        </Grid>

        <Grid item xs={6} style={{textAlign: "right"}}>
        
          <Link
            to="/privacy-policy/"
            style={{ color: "#3d3d3d", textDecoration: "none" }}
          >
            Privacy
          </Link>

        </Grid>

        </Grid>
        </footer>

      {/* CLOSING DIV FOR WHOLE PAGE */}
    </div>
  );
}

